<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">
        Listado de Alertas Dinámicos
      </h6>
    </b-row>

    <b-row class="justify-content-end mb-3">
      <b-col xl="2" md="2">
        <select
          class="form-select form-select-sm rounded-0"
          v-model="superintendence"
        >
          <option :value="null" selected hidden>Superintendente</option>
          <option :value="null"></option>
          <option
            v-for="(item, s) in superintendences"
            :key="s"
            :value="item.userId"
          >
            {{ item.name }}
          </option>
        </select>
      </b-col>
      <b-col xl="3" md="3">
        <b-input-group>
          <label class="form-title mt-1 me-2">Creado:</label>
          <DatePicker v-model="createDate" />
        </b-input-group>
      </b-col>
      <b-col xl="2" md="2">
        <select class="form-select form-select-sm rounded-0" v-model="status">
          <option :value="null" selected hidden>Estado</option>
          <option :value="null"></option>
          <option :value="true">Activo</option>
          <option :value="false">Inactivo</option>
        </select>
      </b-col>
      <b-col xl="2" md="2">
        <b-input-group>
          <b-form-input
            placeholder="Buscar"
            class="rounded-0"
            size="sm"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xl="2" md="2">
        <b-input-group class="mt-1 justify-content-end">
          <b-button class="main-btn" size="sm" @click="refreshTable">
            Aplicar filtro
          </b-button>
        </b-input-group>
      </b-col>
      <b-col class="text-end">
        <b-button class="main-btn" @click="add"> Agregar </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table
            ref="aDynamicTable"
            stacked="md"
            small
            hover
            select-mode="single"
            selectable
            show-empty
            empty-text="No hay registros que mostrar"
            :filter="filter"
            :busy.sync="isBusy"
            :fields="fields"
            :items="retrieveData"
            :current-page="currentPage"
            :per-page="take"
            @filtered="onFiltered"
          >
            <template #cell(createdAt)="data">
              {{ formattedDate(data.item.createdAt) }}
            </template>
            <template #cell(isActive)="data">
              <span @click="changeActive(data.item.id)">
                <b-icon
                  icon="toggle-on"
                  scale="1.5"
                  v-b-tooltip.hover
                  title="Cambiar estado"
                  variant="success"
                  v-if="data.item.isActive"
                />
                <b-icon
                  icon="toggle-off"
                  scale="1.5"
                  v-b-tooltip.hover
                  title="Cambiar estado"
                  variant="danger"
                  v-else
                />
              </span>
            </template>
            <template #cell(type)="data">
              {{ getType(data.item.type) }}
            </template>
            <template #cell(expirationPeriod)="data">
              {{ data.item.expirationPeriod }}
              {{ getExpirationPeriodType(data.item.expirationPeriodType) }}
            </template>
            <template #cell(sendMail)="data">
              {{ data.item.sendMail ? `Sí` : `No` }}
            </template>
            <template #cell(needConfirmation)="data">
              {{ data.item.needConfirmation ? `Sí` : `No` }}
            </template>
            <template #cell(lastModifiedAt)="data">
              {{ formattedDate(data.item.lastModifiedAt) }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  class="sm me-2"
                  @click="edit(data.item)"
                  variant="success"
                >
                  <b-icon icon="pencil" v-b-tooltip.hover title="Editar" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      v-model="addModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <b-form-group class="mt-3">
          <label class="form-title"> Tipo *</label>
          <select
            class="form-select form-select-sm rounded-0"
            v-model="alertDynamic.type"
            :disabled="alertDynamicId"
            @change="selectType"
          >
            <option
              v-for="(item, i) in alertDynamicType"
              :key="i"
              :value="item.key"
            >
              {{ $t(`alert.${item.label}`) }}
            </option>
          </select>
        </b-form-group>

        <AlertDynamicForm :alertDynamic="alertDynamic" ref="alertDynamic" />

        <div class="text-end">
          <b-overlay
            :show="sending"
            rounded
            opacity="0.7"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              class="my-4 main-btn me-3"
              @click="save"
              :disabled="sending"
            >
              <span v-if="alertDynamicId">Editar</span>
              <span v-else>Guardar</span>
            </b-button>
          </b-overlay>
          <b-button class="my-4 btn btn-danger" @click="close">
            <span>Cancelar</span>
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="statusModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      body-bg-variant="light"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <p>¿Esta seguro de cambiar el estado?</p>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay
              :show="sending"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                class="main-btn me-3"
                size="sm"
                @click="updateStatus"
                :disabled="sending"
              >
                Si
              </b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeStatusModal"
              >No</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { alertDynamicType } from "@/config/alertDynamicType";
import { expirationPeriodTypes } from "@/config/expirationPeriodTypes";
import AlertDynamicForm from "./AlertDynamicForm.vue";
import DatePicker from "@/components/widgets/DatePicker.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "alert-dynamic",
  components: {
    AlertDynamicForm,
    DatePicker,
  },
  data() {
    return {
      alertDynamicType: alertDynamicType,
      expirationPeriodTypes: expirationPeriodTypes,
      fields: [
        { key: "createdAt", label: "Creado" },
        { key: "isActive", label: "Activo", class: "text-center" },
        { key: "code", label: "Código", class: "text-center" },
        { key: "type", label: "Tipo" },
        { key: "message", label: "Mensaje" },
        {
          key: "expirationPeriod",
          label: "Periodo de expiración",
          class: "text-center",
        },
        { key: "expirationMessage", label: "Mensaje al expirar el período" },
        {
          key: "sendMail",
          label: "Enviar correo",
          class: "text-center",
        },
        {
          key: "needConfirmation",
          label: "Necesita confirmación",
          class: "text-center",
        },
        { key: "lastModifiedAt", label: "Modificado" },
        { key: "modifiedSuperintendence", label: "Por" },
        { key: "action", label: "Acción" },
      ],
      filter: null,
      search: null,
      superintendence: null,
      createDate: null,
      status: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
      addModal: false,
      alertDynamicId: null,
      alertDynamic: {
        type: null,
        message: null,
        expirationPeriod: null,
        expirationPeriodType: null,
        expirationMessage: null,
        sendMail: false,
        needConfirmation: false,
        emailMessage: null,
      },
      sending: false,
      tag: null,
      statusModal: false,
      superintendences: [],
    };
  },
  mounted() {
    this.getSuperintendences();
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    reset() {
      this.alertDynamicId = null;
      this.alertDynamic = {
        type: null,
        message: null,
        expirationPeriod: null,
        expirationPeriodType: null,
        expirationMessage: null,
        sendMail: false,
        needConfirmation: false,
        emailMessage: null,
      };
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
    },
    filterTable() {
      this.currentPage = 1;
      this.filter = this.search;
    },
    refreshTable() {
      this.filterTable();
      this.$refs.aDynamicTable.refresh();
    },
    async getSuperintendences() {
      try {
        var response =
          await RepositoryFactory.alertRepository.getSuperintendences();
        this.superintendences = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async retrieveData(context) {
      try {
        this.isBusy = true;
        let payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          createDate: this.createDate,
          status: this.status,
          superintendence: this.superintendence,
        };
        let response = await RepositoryFactory.alertRepository.getPagedDynamic(
          payload
        );
        this.rows = response.data.total;
        let items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    add() {
      this.reset();
      this.addModal = true;
    },
    close() {
      this.addModal = false;
      this.reset();
    },
    async changeActive(id) {
      this.alertDynamicId = id;
      this.statusModal = true;
    },
    closeStatusModal() {
      this.statusModal = false;
      this.reset();
    },
    async updateStatus() {
      try {
        this.sending = true;
        await RepositoryFactory.alertRepository.updateDynamicActive({
          id: this.alertDynamicId,
        });
        this.closeStatusModal();
        this.$refs.aDynamicTable.refresh();
      } catch (error) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: `Error al consumir servicio`,
        });
      } finally {
        this.sending = false;
      }
    },
    async save() {
      let flag = true;
      let tags = this.extractTags(this.alertDynamic.message);
      tags.forEach((tag) => {
        let x = this.$refs.alertDynamic.alertDynamicTag.find((x) => x === tag);
        if (x === undefined) {
          flag = false;
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: `Esta etiqueta es incorrecta: [[${tag}]]`,
          });
          return;
        }
      });

      if (flag) {
        try {
          this.sending = true;
          if (this.alertDynamic.expirationPeriod === "") {
            this.alertDynamic.expirationPeriod = null;
          }
          if (this.alertDynamicId) {
            await RepositoryFactory.alertRepository.updateDynamic({
              id: this.alertDynamicId,
              alertDynamic: this.alertDynamic,
            });
          } else {
            await RepositoryFactory.alertRepository.addDynamic({
              alertDynamic: this.alertDynamic,
            });
          }
          this.close();
          this.$refs.aDynamicTable.refresh();
        } catch (error) {
          window.console.log(error);
          let message = "Error al consumir servicio.";

          if (error?.response?.status === 400 && error?.response?.data) {
            if (typeof error.response.data === "object") {
              message = "";
              if (
                error.response.data?.title ===
                  "One or more validation errors occurred." &&
                error.response.data?.errors
              ) {
                var keyValidation = Object.keys(error.response.data.errors)[0];
                if (keyValidation.includes("type")) {
                  message = "El tipo es requerido.";
                } else {
                  message = "Error al consumir servicio.";
                }
              } else {
                error.response.data.forEach((item) => {
                  let msg = this.$t(item.value);
                  message += `<p>${msg}</p>`;
                });
              }
            } else if (typeof error.response.data === "string") {
              message = this.$t(error.response.data);
            }
          }

          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: message,
          });
        } finally {
          this.sending = false;
        }
      }
    },
    edit(item) {
      this.alertDynamicId = item.id;
      this.alertDynamic = {
        type: item.type,
        message: item.message,
        expirationPeriod: item.expirationPeriod,
        expirationPeriodType: item.expirationPeriodType,
        expirationMessage: item.expirationMessage,
        sendMail: item.sendMail,
        needConfirmation: item.needConfirmation,
        emailMessage: item.emailMessage,
      };
      this.addModal = true;
    },
    setTag() {
      let message = this.alertDynamic.message
        ? `${this.alertDynamic.message}`
        : "";
      let tag = this.tag ? `[[${this.tag}]]` : "";
      this.alertDynamic.message = `${message}${tag}`;
      this.tag = null;
    },
    extractTags(message) {
      const regex = /\[\[(.*?)\]\]/g;
      const tags = [];
      let match;

      while ((match = regex.exec(message)) !== null) {
        tags.push(match[1]);
      }

      return tags;
    },
    getType(value) {
      const t = this.alertDynamicType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    getExpirationPeriodType(value) {
      const t = this.expirationPeriodTypes.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    formattedDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : "";
    },
    selectType() {
      this.alertDynamic.message = null;
      switch (this.alertDynamic.type) {
        case 0:
          this.alertDynamic.message = `Debe realizar y confirmar la actualización de todas sus [[AmountOfSocieties]] Personas Jurídicas y Beneficiarios finales agregados en su plataforma de Beneficiario Final. Usted cuenta con [[ExpirationPeriod]]. Desde el [[DateSent]], para confirmar que todos sus datos están actualizados.

Actualmente, le quedan [[DifferenceDateSent]] para confirmar que sus datos están actualizados.`;
          break;
        case 1:
          this.alertDynamic.message = `Debe realizar la desvinculación de los siguientes folios, [[Folios]] en la plataforma de Beneficiario Final.`;
          break;
        default:
          break;
      }
    },
  },
};
</script>
