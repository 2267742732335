<template>
  <div class="mt-5 px-xl-5">
    <h6 class="page-title pb-2 text-uppercase">
      <b-button class="main-btn rounded me-2" @click="goToSuperintendence">
        <b-icon icon="arrow-left"></b-icon
      ></b-button>
      {{ $t("navbar.superintendence") }}
    </h6>
    <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
      <b-tab
        title="Enviar Alerta"
        title-item-class="dash-tab"
        @click="changeTab(0)"
      >
        <SendAlert v-if="tabIndex === 0" />
      </b-tab>
      <b-tab
        title="Alertas Enviados"
        title-item-class="dash-tab"
        @click="changeTab(1)"
      >
        <AlertSentStatus v-if="tabIndex === 1" />
      </b-tab>
      <b-tab
        title="Configuración de Alerta Dinámica"
        title-item-class="dash-tab"
        @click="changeTab(2)"
      >
        <AlertDynamic v-if="tabIndex === 2" />
      </b-tab>
      <b-tab
        title="Configuración de Alerta Automática"
        title-item-class="dash-tab"
        @click="changeTab(3)"
      >
        <AlertAutomatic v-if="tabIndex === 3" />
      </b-tab>
      <b-tab
        title="Configuración de Grupo Sociedades"
        title-item-class="dash-tab"
        @click="changeTab(4)"
      >
        <LegalEntityGroup v-if="tabIndex === 4" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SendAlert from "./SendAlert";
import AlertSentStatus from "./AlertSentStatus";
import AlertDynamic from "./AlertDynamic";
import AlertAutomatic from "./AlertAutomatic";
import LegalEntityGroup from "./LegalEntityGroup";

export default {
  name: "alerts",
  components: {
    SendAlert,
    AlertSentStatus,
    AlertDynamic,
    AlertAutomatic,
    LegalEntityGroup
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    goToSuperintendence() {
      this.$router.push({
        name: "superintendences",
      });
    },
    changeTab(index) {
      this.tabIndex = index;
    },
  },
};
</script>
