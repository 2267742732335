<template>
  <div>
    <b-row class="justify-content-end mb-2">
      <b-col xl="3" md="3">
        <b-input-group>
          <label class="form-title mt-1 me-2">Desde:</label>
          <DatePicker v-model="startDate" />
        </b-input-group>
      </b-col>
      <b-col xl="3" md="3">
        <b-input-group>
          <label class="form-title mt-1 me-2">Hasta:</label>
          <DatePicker v-model="endDate" />
        </b-input-group>
      </b-col>
      <b-col xl="2" md="2" v-if="!isResidentAgent">
        <select class="form-select form-select-sm rounded-0" v-model="type">
          <option :value="null" selected hidden>Tipo de alerta</option>
          <option :value="null"></option>
          <option v-for="(item, i) in alertAutomaticType" :key="i" :value="item.key">
            {{ $t(`alert.${item.label}`) }}
          </option>
        </select>
      </b-col>
      <b-col xl="2" md="2" v-if="!isResidentAgent">
        <select class="form-select form-select-sm rounded-0" v-model="status">
          <option :value="null" selected hidden>Estado</option>
          <option :value="null"></option>
          <option v-for="(item, i) in alertStatus" :key="i" :value="item.key">
            {{ $t(`alert.${item.label}`) }}
          </option>
        </select>
      </b-col>
      <b-col xl="2" md="2" v-if="!isResidentAgent">
        <b-input-group>
          <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>

    <b-row class="justify-content-end mb-3">
      <b-col xl="2" md="2">
        <b-input-group class="mt-1 justify-content-end">
          <b-button class="main-btn" size="sm" @click="refreshTable">
            Aplicar filtro
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table ref="aSentStatusTable" responsive stacked="md" small hover select-mode="single" selectable show-empty
      empty-text="No hay registros que mostrar" :filter="filter" :busy.sync="isBusy" :fields="fields"
      :items="retrieveData" :current-page="currentPage" :per-page="take">
      <template #cell(created)="data">
        {{ formattedDate(data.item.alertAutomaticNotification.createdAt) }}
      </template>
      <template #cell(type)="data">
        {{ getType(data.item.alertAutomaticNotification.type) }}
      </template>
      <template #cell(name)="data">
        {{ data.item.lawFirm.name }}
      </template>
      <template #cell(cur)="data">
        {{ data.item.lawFirm.cur }}
      </template>
      <template #cell(needConfirmation)="data">
        {{
            data.item.alertAutomaticNotification.needConfirmation ? `Sí` : `No`
          }}
      </template>
      <template #cell(status)="data">
        {{ getAlertStatus(data.item.status) }}
      </template>
    </b-table>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
          class="my-0 justify-content-end">
          <template #first-text>
            <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
          </template>
          <template #last-text>
            <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { alertAutomaticType } from "@/config/alertAutomaticType";
import { alertStatus } from "@/config/alertStatus";
import DatePicker from "@/components/widgets/DatePicker.vue";
import moment from "moment";

export default {
  components: {
    DatePicker,
  },
  props: {
    isResidentAgent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alertAutomaticType: alertAutomaticType,
      alertStatus: alertStatus,
      isBusy: false,
      fields: [
        { key: "created", label: "Enviado" },
        { key: "type", label: "Tipo" },
        { key: "name", label: "Abogado/Firma" },
        { key: "cur", label: "CUR" },
        { key: "status", label: "Estado" },
      ],
      currentPage: 1,
      take: 10,
      rows: 0,
      filter: null,
      search: null,
      status: null,
      type: null,
      startDate: null,
      endDate: null,
    };
  },
  methods: {
    async retrieveData(context) {
      try {
        this.isBusy = true;
        var payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          type: this.type,
          status: this.status,
          startDate: this.startDate,
          endDate: this.endDate,
        };

        if (this.isResidentAgent) {
          payload.isResidentAgent = true;
        }

        var response =
          await RepositoryFactory.alertRepository.getAutomaticSentStatus(
            payload
          );

        this.rows = response.data.total;
        var items = response.data.items;

        items.forEach((item) => {
          item.lawFirm.name = item.lawFirm.name.toUpperCase()
        });

        return items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    filterTable() {
      this.currentPage = 1;
      this.filter = this.search;
    },
    refreshTable() {
      this.filterTable();
      this.$refs.aSentStatusTable.refresh();
    },
    formattedDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getType(value) {
      const t = this.alertAutomaticType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    getAlertStatus(value) {
      const t = this.alertStatus.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
  },
};
</script>
