<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">Alertas Enviados</h6>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
          <b-tab
            title="Manual"
            title-item-class="dash-tab"
            @click="changeTab(0)"
          >
            <TableAlertManualNotification v-if="tabIndex === 0" class="mt-4" />
          </b-tab>
          <b-tab
            title="Dinámico"
            title-item-class="dash-tab"
            @click="changeTab(1)"
          >
            <TableAlertDynamicNotification v-if="tabIndex === 1" class="mt-4" />
          </b-tab>
          <b-tab
            title="Automático"
            title-item-class="dash-tab"
            @click="changeTab(2)"
          >
            <TableAlertAutomaticNotification
              v-if="tabIndex === 2"
              class="mt-4"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TableAlertManualNotification from "./alert-sent-status/TableAlertManualNotification";
import TableAlertDynamicNotification from "./alert-sent-status/TableAlertDynamicNotification";
import TableAlertAutomaticNotification from "./alert-sent-status/TableAlertAutomaticNotification";

export default {
  name: "alert-sent-status",
  components: {
    TableAlertManualNotification,
    TableAlertDynamicNotification,
    TableAlertAutomaticNotification,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {},
};
</script>
