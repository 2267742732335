<template>
  <div>
    <VueTimepicker v-model="time" :format="timeFormat" input-width="100%" />
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "time-picker",
  components: {
    VueTimepicker,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      time: this.value ? this.value : null,
      timeFormat: "hh:mm:ss A",
    };
  },
  watch: {
    value(value) {
      this.time = value ? value : null;
    },
    time(time) {
      this.$emit("input", time);
    },
  },
};
</script>

<style scoped>
/* Estilos personalizados aquí si es necesario */
</style>
