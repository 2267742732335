<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group class="mt-3">
          <label class="form-title"> Mensaje * </label>
          <b-form-textarea
            v-model="alertManual.message"
            size="sm"
            required
            class="rounded-0"
            rows="7"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <b-form-checkbox v-model="alertManual.sendMail" switch>
            <span class="form-title"> Enviar Correo </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "alert-manual-form",
  props: {
    alertManual: {
      type: Object,
      required: null,
    },
  },
};
</script>
