<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">
        Listado de Alertas Automáticas
      </h6>
    </b-row>

    <b-row class="justify-content-end mb-3">
      <b-col xl="2" md="2">
        <select class="form-select form-select-sm rounded-0" v-model="status">
          <option :value="null" selected hidden>Estado</option>
          <option :value="null"></option>
          <option :value="true">Activo</option>
          <option :value="false">Inactivo</option>
        </select>
      </b-col>
      <b-col xl="2" md="2">
        <b-input-group class="mt-1 justify-content-end">
          <b-button class="main-btn" size="sm" @click="refreshTable">
            Aplicar filtro
          </b-button>
        </b-input-group>
      </b-col>
      <b-col class="text-end">
        <b-button class="main-btn" @click="add"> Agregar </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table
            ref="aAutomaticTable"
            stacked="md"
            small
            hover
            select-mode="single"
            selectable
            show-empty
            empty-text="No hay registros que mostrar"
            :filter="filter"
            :busy.sync="isBusy"
            :fields="fields"
            :items="retrieveData"
            :current-page="currentPage"
            :per-page="take"
            @filtered="onFiltered"
          >
            <template #cell(type)="data">
              {{ getType(data.item.type) }}
            </template>
            <template #cell(sendMail)="data">
              {{ data.item.sendMail ? `Sí` : `No` }}
            </template>
            <template #cell(isActive)="data">
              {{ data.item.isActive ? `Sí` : `No` }}
            </template>
            <template #cell(lastModifiedAt)="data">
              {{ formattedDate(data.item.lastModifiedAt) }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  class="me-2"
                  @click="edit(data.item)"
                  variant="success"
                >
                  <b-icon icon="pencil" v-b-tooltip.hover title="Editar" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      v-model="addModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <b-form-group class="mt-3">
          <label class="form-title"> Tipo *</label>
          <select
            class="form-select form-select-sm rounded-0"
            v-model="alertAutomatic.type"
            :disabled="alertAutomaticId"
            @change="selectType"
          >
            <option
              v-for="(item, i) in alertAutomaticType"
              :key="i"
              :value="item.key"
            >
              {{ $t(`alert.${item.label}`) }}
            </option>
          </select>
        </b-form-group>

        <b-icon-question-circle-fill
          class="ms-2 card-title-color"
          :title="help"
          v-if="help"
        />

        <AlertAutomaticForm :alertAutomatic="alertAutomatic" />

        <div class="text-end">
          <b-overlay
            :show="sending"
            rounded
            opacity="0.7"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              class="my-4 main-btn me-3"
              @click="save"
              :disabled="sending"
            >
              <span v-if="alertAutomaticId">Editar</span>
              <span v-else>Guardar</span>
            </b-button>
          </b-overlay>
          <b-button class="my-4 btn btn-danger" @click="close">
            <span>Cancelar</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { alertAutomaticType } from "@/config/alertAutomaticType";
import AlertAutomaticForm from "./AlertAutomaticForm.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "alert-automatic",
  components: {
    AlertAutomaticForm,
  },
  data() {
    return {
      alertAutomaticType: alertAutomaticType,
      fields: [
        { key: "code", label: "Código", class: "text-center" },
        { key: "type", label: "Tipo" },
        { key: "message", label: "Mensaje" },
        {
          key: "sendMail",
          label: "Enviar correo",
          class: "text-center",
        },
        { key: "isActive", label: "Activo", class: "text-center" },
        { key: "lastModifiedAt", label: "Modificado" },
        { key: "modifiedSuperintendence", label: "Por" },
        { key: "action", label: "Acción" },
      ],
      filter: null,
      search: null,
      status: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
      addModal: false,
      alertAutomaticId: null,
      alertAutomatic: {
        type: null,
        message: null,
        sendMail: false,
        isActive: false,
      },
      sending: false,
      help: null,
    };
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    reset() {
      this.alertAutomaticId = null;
      this.alertAutomatic = {
        type: null,
        message: null,
        sendMail: false,
        isActive: false,
      };
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
    },
    filterTable() {
      this.currentPage = 1;
      this.filter = this.search;
    },
    refreshTable() {
      this.filterTable();
      this.$refs.aAutomaticTable.refresh();
    },
    async retrieveData(context) {
      try {
        this.isBusy = true;
        let payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          status: this.status,
        };
        let response =
          await RepositoryFactory.alertRepository.getPagedAutomatic(payload);
        this.rows = response.data.total;
        let items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    add() {
      this.reset();
      this.addModal = true;
    },
    close() {
      this.addModal = false;
      this.reset();
    },
    async save() {
      try {
        this.sending = true;
        if (this.alertAutomaticId) {
          await RepositoryFactory.alertRepository.updateAutomatic({
            id: this.alertAutomaticId,
            alertAutomatic: this.alertAutomatic,
          });
        } else {
          await RepositoryFactory.alertRepository.addAutomatic({
            alertAutomatic: this.alertAutomatic,
          });
        }
        this.close();
        this.$refs.aAutomaticTable.refresh();
      } catch (error) {
        window.console.log(error);
        let message = "Error al consumir servicio.";

        if (error?.response?.status === 400 && error?.response?.data) {
          if (typeof error.response.data === "object") {
            message = "";
            if (
              error.response.data?.title ===
                "One or more validation errors occurred." &&
              error.response.data?.errors
            ) {
              var keyValidation = Object.keys(error.response.data.errors)[0];
              if (keyValidation.includes("type")) {
                message = "El tipo es requerido.";
              } else {
                message = "Error al consumir servicio.";
              }
            } else {
              error.response.data.forEach((item) => {
                let msg = this.$t(item.value);
                message += `<p>${msg}</p>`;
              });
            }
          } else if (typeof error.response.data === "string") {
            message = this.$t(error.response.data);
          }
        }

        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: message,
        });
      } finally {
        this.sending = false;
      }
    },
    edit(item) {
      this.alertAutomaticId = item.id;
      this.alertAutomatic = {
        type: item.type,
        message: item.message,
        sendMail: item.sendMail,
        isActive: item.isActive,
      };
      this.addModal = true;
    },
    getType(value) {
      const t = this.alertAutomaticType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    formattedDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : "";
    },
    selectType() {
      this.help = null;
      switch (this.alertAutomatic.type) {
        case 5:
          this.help = `(El AR tiene 5 días hábiles para registrarse): Envío de correo indicándole que su término de registro en el sistema está próximo a vencer. Este comunicado se enviará al 3 día hábil si el AR no se ha registrado.`;
          break;
        case 6:
          this.help = `Una semana antes del vencimiento de carga. Esta se va a poder obtener con la nueva columna de días que tiene el AR para subir las sociedades.`;
          break;
        case 9:
          this.help = `Se debe enviar una notificación cuando el periodo de carga se ha vencido.  Envió notificación RUBF y envío de correo.  Esta se va a poder obtener con la nueva columna de días que tiene el AR para subir las sociedades.`;
          break;
        default:
          break;
      }
    },
  },
};
</script>
