<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group class="mt-3">
          <label class="form-title"> Período de vencimiento </label>
          <b-form-input
            v-model="alertDynamic.expirationPeriod"
            size="sm"
            required
            class="rounded-0"
            type="number"
            @input="setEndDate"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mt-3">
          <label class="form-title"> Tipo de período de vencimiento </label>
          <select
            class="form-select form-select-sm rounded-0"
            v-model="alertDynamic.expirationPeriodType"
            @change="setEndDate"
          >
            <option :value="null"></option>
            <option
              v-for="(item, e) in expirationPeriodTypes"
              :key="e"
              :value="item.key"
            >
              {{ $t(`alert.${item.label}`) }}
            </option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group class="mt-3">
          <label class="form-title"> Fecha de vencimiento </label>
          <DatePicker
            v-model="alertDynamic.endDate"
            :disabledBeforeToday="true"
            :initDate="sendDate"
            @input="changeEndDate"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mt-3">
          <label class="form-title"> Hora de vencimiento </label>
          <TimePicker v-model="alertDynamic.endHour" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group class="mt-3">
      <label class="form-title">
        Mensaje *
        <small
          >(Puede seleccionar una etiqueta para incluirla en la posición
          deseada)</small
        ></label
      >
      <b-icon-question-circle-fill
        class="ms-2 card-title-color"
        title="Esta etiqueta es evaluada por el código para agregar el dato correspondiente."
      />
      <select
        class="form-select form-select-sm rounded-0"
        v-model="tag"
        @change="setTag"
        ref="alertDynamicTag"
      >
        <option value="" hidden selected>
          Seleccione etiqueta para colocar en el cuerpo del mensaje su valor
          correspondiente
        </option>
        <option v-for="(item, t) in alertDynamicTag" :key="t" :value="item">
          {{ $t(`alert.${item}`) }}
        </option>
      </select>
      <b-form-textarea
        v-model="alertDynamic.message"
        size="sm"
        required
        class="rounded-0 mt-2"
        rows="7"
      />
    </b-form-group>
    <b-form-group class="mt-3">
      <label class="form-title"> Mensaje para vencimiento de período </label>
      <b-form-textarea
        v-model="alertDynamic.expirationMessage"
        size="sm"
        required
        class="rounded-0"
        rows="7"
      />
    </b-form-group>
    <b-form-group class="mt-3">
      <label class="form-title"> Mensaje para correo electrónico </label>
      <b-form-textarea
        v-model="alertDynamic.emailMessage"
        size="sm"
        required
        class="rounded-0"
        rows="7"
      />
    </b-form-group>
    <b-form-group class="mt-3">
      <b-form-checkbox v-model="alertDynamic.sendMail" switch>
        <span class="form-title"> Enviar Correo </span>
      </b-form-checkbox>
    </b-form-group>
    <b-form-group class="mt-3">
      <b-form-checkbox v-model="alertDynamic.needConfirmation" switch>
        <span class="form-title"> Necesita confirmación </span>
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { expirationPeriodTypes } from "@/config/expirationPeriodTypes";
import { addPeriod } from "@/services/alerts";
import DatePicker from "@/components/widgets/DatePicker.vue";
import TimePicker from "@/components/widgets/TimePicker.vue";
import moment from "moment";

export default {
  name: "alert-dynamic-form",
  components: {
    DatePicker,
    TimePicker,
  },
  props: {
    alertDynamic: {
      type: Object,
      required: true,
    },
    sendDate: {
      required: false,
    },
    sendTime: {
      required: false,
    },
  },
  data() {
    return {
      expirationPeriodTypes: expirationPeriodTypes,
      alertDynamicTag: [],
      tag: null,
    };
  },
  mounted() {
    this.setAlertDynamicTags();
  },
  watch: {
    "alertDynamic.type"() {
      this.setAlertDynamicTags();
      this.setEndDate();
    },
    sendDate() {
      this.setEndDate();
    },
    sendTime() {
      this.setEndDate();
    },
  },
  methods: {
    setAlertDynamicTags() {
      this.alertDynamicTag = [];
      switch (this.alertDynamic.type) {
        case 0:
          this.alertDynamicTag = [
            "AmountOfSocieties",
            "ExpirationPeriod",
            "DateSent",
            "DifferenceDateSent",
          ];
          break;
        case 1:
          this.alertDynamicTag = [
            "Folios",
            "ExpirationPeriod",
            "DateSent",
            "DifferenceDateSent",
          ];
          break;
      }
    },
    setTag() {
      let message = this.alertDynamic.message
        ? `${this.alertDynamic.message}`
        : "";
      let tag = this.tag ? `[[${this.tag}]]` : "";
      this.alertDynamic.message = `${message}${tag}`;
      this.tag = null;
    },
    async changeEndDate(date) {
      if (
        date &&
        this.alertDynamic.expirationPeriod != null &&
        this.alertDynamic.expirationPeriodType != null
      ) {
        const sendDate = moment(this.alertDynamic.sendDate);
        const endDate = moment(date);
        const difference = endDate.diff(sendDate, "days");

        if (difference > 0) {
          this.alertDynamic.expirationPeriod = difference;
          this.alertDynamic.expirationPeriodType = 2;
          this.alertDynamic.endHour = this.alertDynamic.sendTime;
        }
      }
    },
    setEndDate() {
      this.alertDynamic.endDate = null;
      this.alertDynamic.endHour = {
        hh: "",
        mm: "",
        ss: "",
        A: "",
      };
      var sendDate = null;

      if (
        this.alertDynamic.sendDate != null &&
        this.alertDynamic.sendDate != "" &&
        this.alertDynamic.sendTime != null &&
        this.alertDynamic.sendTime != "" &&
        this.alertDynamic.sendTime.hh != "" &&
        this.alertDynamic.sendTime.mm != "" &&
        this.alertDynamic.sendTime.ss != "" &&
        this.alertDynamic.sendTime.A != "" &&
        this.alertDynamic.expirationPeriod != null &&
        this.alertDynamic.expirationPeriod != "" &&
        this.alertDynamic.expirationPeriodType != null
      ) {
        sendDate = moment(this.alertDynamic.sendDate);
        var hour = parseInt(this.alertDynamic.sendTime.hh);
        var minute = parseInt(this.alertDynamic.sendTime.mm);
        var second = parseInt(this.alertDynamic.sendTime.ss);
        sendDate.hours(hour);
        sendDate.minutes(minute);
        sendDate.seconds(second);
      }

      if (sendDate !== null) {
        let differenceDateSent = addPeriod(
          sendDate,
          this.alertDynamic.expirationPeriod,
          this.alertDynamic.expirationPeriodType
        );

        this.alertDynamic.endDate = differenceDateSent.format("YYYY-MM-DD");
        this.alertDynamic.endHour = {
          hh: differenceDateSent.format("hh"),
          mm: differenceDateSent.format("mm"),
          ss: differenceDateSent.format("ss"),
          A: differenceDateSent.format("A"),
        };
      }
    },
  },
};
</script>
