<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">
        Listado de Grupos según Sociedades
      </h6>
    </b-row>

    <b-row class="justify-content-end mb-3">
      <b-col class="text-end">
        <b-button class="main-btn" @click="add"> Agregar </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table
            ref="legalEntityGroupTable"
            stacked="md"
            small
            hover
            select-mode="single"
            selectable
            show-empty
            empty-text="No hay registros que mostrar"
            :filter="search"
            :busy.sync="isBusy"
            :fields="fields"
            :items="retrieveData"
            :current-page="currentPage"
            :per-page="take"
            @filtered="onFiltered"
          >
            <template #cell(amount)="data">
              {{ data.item.valueStart }} - {{ data.item.valueEnd }}
            </template>
            <template #cell(isActive)="data">
              {{ data.item.isActive ? `Sí` : `No` }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  class="me-2"
                  @click="edit(data.item)"
                  variant="success"
                >
                  <b-icon icon="pencil" v-b-tooltip.hover title="Editar" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      v-model="addModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <h6>Grupo de Sociedades</h6>
        <b-row>
          <b-col>
            <b-form-group class="mt-3">
              <label class="form-title"> Desde *</label>
              <b-form-input
                class="rounded-0"
                type="number"
                v-model="posts.valueStart"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="mt-3">
              <label class="form-title"> Hasta *</label>
              <b-form-input
                class="rounded-0"
                type="number"
                v-model="posts.valueEnd"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group class="mt-3">
              <label class="form-title"> Días *</label>
              <b-form-input
                class="rounded-0"
                type="number"
                v-model="posts.days"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="mt-5">
              <b-form-checkbox v-model="posts.isActive" switch>
                <span class="form-title"> Activo </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="text-end">
          <b-overlay
            :show="sending"
            rounded
            opacity="0.7"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              class="my-4 main-btn me-3"
              @click="save"
              :disabled="sending"
            >
              <span v-if="LegalEntityGroupId">Editar</span>
              <span v-else>Guardar</span>
            </b-button>
          </b-overlay>
          <b-button class="my-4 btn btn-danger" @click="close">
            <span>Cancelar</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";
//import moment from "moment";

export default {
  name: "legal-entity-group",
  components: {},
  data() {
    return {
      LegalEntityGroupId: null,
      posts: {
        valueStart: null,
        valueEnd: null,
        days: null,
        isActive: true,
      },
      fields: [
        { key: "code", label: "Código", class: "text-center" },
        {
          key: "amount",
          label: "Cantidad de Sociedades",
          class: "text-center",
        },
        { key: "days", label: "Días Calendario", class: "text-center" },
        { key: "isActive", label: "Activo", class: "text-center" },
        { key: "action", label: "Acción" },
      ],
      search: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
      addModal: false,
      sending: false,
      help: null,
    };
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    reset() {
      this.LegalEntityGroupId = null;
      this.posts = {
        valueStart: null,
        valueEnd: null,
        days: null,
        isActive: true,
      };
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
    },
    async retrieveData(context) {
      try {
        this.isBusy = true;
        let payload = {
          page: context.currentPage,
          take: this.take,
          search: context.search,
        };
        let response =
          await RepositoryFactory.alertRepository.getPagedLegalEntityGroup(
            payload
          );
        this.rows = response.data.total;
        let items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    add() {
      this.reset();
      this.addModal = true;
    },
    close() {
      this.addModal = false;
      this.reset();
    },
    async save() {
      try {
        this.sending = true;
        if (this.LegalEntityGroupId) {
          await RepositoryFactory.alertRepository.updateLegalEntityGroup({
            id: this.LegalEntityGroupId,
            legalEntityGroup: this.posts,
          });
        } else {
          await RepositoryFactory.alertRepository.addLegalEntityGroup({
            legalEntityGroup: this.posts,
          });
        }
        this.close();
        this.$refs.legalEntityGroupTable.refresh();
      } catch (error) {
        window.console.log(error);
        let message = "Error al consumir servicio.";

        if (error?.response?.status === 400 && error?.response?.data) {
          if (typeof error.response.data === "object") {
            message = "";
            if (
              error.response.data?.title ===
                "One or more validation errors occurred." &&
              error.response.data?.errors
            ) {
              var keyValidation = Object.keys(error.response.data.errors)[0];
              if (keyValidation.includes("valueStart")) {
                message = "Desde es requerido.";
              } else if (keyValidation.includes("valueEnd")) {
                message = "Hasta es requerido.";
              } else if (keyValidation.includes("days")) {
                message = "Días es requerido.";
              } else {
                message = "Error al consumir servicio.";
              }
            } else {
              error.response.data.forEach((item) => {
                let msg = this.$t(item.value);
                message += `<p>${msg}</p>`;
              });
            }
          } else if (typeof error.response.data === "string") {
            message = this.$t(error.response.data);
          }
        }

        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: message,
        });
      } finally {
        this.sending = false;
      }
    },
    edit(item) {
      this.LegalEntityGroupId = item.id;

      this.posts = {
        valueStart: item.valueStart,
        valueEnd: item.valueEnd,
        days: item.days,
        isActive: item.isActive,
      };

      this.addModal = true;
    },
  },
};
</script>
