export const alertAutomaticType = [
  { key: 0, label: "ActiveCur" },
  { key: 1, label: "DisabledCur" },
  { key: 2, label: "InvitationsMade" },
  { key: 3, label: "AcceptedInvitation" },
  { key: 4, label: "SocietyElimination" },
  { key: 5, label: "RegistrationPeriodExpire" },
  { key: 6, label: "LoadingPhaseExpire" },
  { key: 7, label: "RpAddingOrModifyingSociety" },
  { key: 8, label: "LoadCompliancePercentage" },
  { key: 9, label: "ExpiredChargePhase" },
  { key: 10, label: "ExpiredRegistrationPeriod" },
  { key: 11, label: "NotifySocietyElimination" },
];
